@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Fredoka:wght@300..700&display=swap');

* {
    box-sizing: border-box !important;

}

a {
    text-decoration: none !important;
}

body {
    background: linear-gradient(18deg, rgba(153, 69, 255, 1) 20%, rgba(20, 241, 149, 1) 100%) !important;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #000;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(18deg, rgba(153, 69, 255, 1) 20%, rgba(20, 241, 149, 1) 100%);

}

.fly-1 {
    animation: fly-around-1 21s infinite;
}

@-moz-keyframes fly-around-1 {
    0% {
        transform: translate(13vw, 50vh);
    }

    33% {
        transform: translate(75.0629343221627vw, 2.289970295918446vh);
    }

    66% {
        transform: translate(89.55592178429997vw, 87.4683070215884vh);
    }

    100% {
        transform: translate(13vw, 50vh);
    }
}

@-webkit-keyframes fly-around-1 {
    0% {
        transform: translate(13vw, 50vh);
    }

    33% {
        transform: translate(75.0629343221627vw, 2.289970295918446vh);
    }

    66% {
        transform: translate(89.55592178429997vw, 87.4683070215884vh);
    }

    100% {
        transform: translate(13vw, 50vh);
    }
}

@-o-keyframes fly-around-1 {
    0% {
        transform: translate(13vw, 50vh);
    }

    33% {
        transform: translate(75.0629343221627vw, 2.289970295918446vh);
    }

    66% {
        transform: translate(89.55592178429997vw, 87.4683070215884vh);
    }

    100% {
        transform: translate(13vw, 50vh);
    }
}

@keyframes fly-around-1 {
    0% {
        transform: translate(13vw, 50vh);
    }

    33% {
        transform: translate(75.0629343221627vw, 2.289970295918446vh);
    }

    66% {
        transform: translate(89.55592178429997vw, 87.4683070215884vh);
    }

    100% {
        transform: translate(13vw, 50vh);
    }
}

.fly-2 {
    animation: fly-around-2 22s infinite;
}

@-moz-keyframes fly-around-2 {
    0% {
        transform: translate(26vw, 50vh);
    }

    33% {
        transform: translate(8.759612215840281vw, 21.930413300503854vh);
    }

    66% {
        transform: translate(51.868460973870334vw, 71.5020715721206vh);
    }

    100% {
        transform: translate(26vw, 50vh);
    }
}

@-webkit-keyframes fly-around-2 {
    0% {
        transform: translate(26vw, 50vh);
    }

    33% {
        transform: translate(8.759612215840281vw, 21.930413300503854vh);
    }

    66% {
        transform: translate(51.868460973870334vw, 71.5020715721206vh);
    }

    100% {
        transform: translate(26vw, 50vh);
    }
}

@-o-keyframes fly-around-2 {
    0% {
        transform: translate(26vw, 50vh);
    }

    33% {
        transform: translate(8.759612215840281vw, 21.930413300503854vh);
    }

    66% {
        transform: translate(51.868460973870334vw, 71.5020715721206vh);
    }

    100% {
        transform: translate(26vw, 50vh);
    }
}

@keyframes fly-around-2 {
    0% {
        transform: translate(26vw, 50vh);
    }

    33% {
        transform: translate(8.759612215840281vw, 21.930413300503854vh);
    }

    66% {
        transform: translate(51.868460973870334vw, 71.5020715721206vh);
    }

    100% {
        transform: translate(26vw, 50vh);
    }
}

.fly-3 {
    animation: fly-around-3 23s infinite;
}

@-moz-keyframes fly-around-3 {
    0% {
        transform: translate(39vw, 50vh);
    }

    33% {
        transform: translate(31.87005875110758vw, 28.775637541364834vh);
    }

    66% {
        transform: translate(88.22957058730834vw, 74.399229508218vh);
    }

    100% {
        transform: translate(39vw, 50vh);
    }
}

@-webkit-keyframes fly-around-3 {
    0% {
        transform: translate(39vw, 50vh);
    }

    33% {
        transform: translate(31.87005875110758vw, 28.775637541364834vh);
    }

    66% {
        transform: translate(88.22957058730834vw, 74.399229508218vh);
    }

    100% {
        transform: translate(39vw, 50vh);
    }
}

@-o-keyframes fly-around-3 {
    0% {
        transform: translate(39vw, 50vh);
    }

    33% {
        transform: translate(31.87005875110758vw, 28.775637541364834vh);
    }

    66% {
        transform: translate(88.22957058730834vw, 74.399229508218vh);
    }

    100% {
        transform: translate(39vw, 50vh);
    }
}

@keyframes fly-around-3 {
    0% {
        transform: translate(39vw, 50vh);
    }

    33% {
        transform: translate(31.87005875110758vw, 28.775637541364834vh);
    }

    66% {
        transform: translate(88.22957058730834vw, 74.399229508218vh);
    }

    100% {
        transform: translate(39vw, 50vh);
    }
}

.fly-4 {
    animation: fly-around-4 24s infinite;
}

@-moz-keyframes fly-around-4 {
    0% {
        transform: translate(52vw, 50vh);
    }

    33% {
        transform: translate(13.665573745835104vw, 2.314085783752593vh);
    }

    66% {
        transform: translate(70.08688741361918vw, 5.804331585651835vh);
    }

    100% {
        transform: translate(52vw, 50vh);
    }
}

@-webkit-keyframes fly-around-4 {
    0% {
        transform: translate(52vw, 50vh);
    }

    33% {
        transform: translate(13.665573745835104vw, 2.314085783752593vh);
    }

    66% {
        transform: translate(70.08688741361918vw, 5.804331585651835vh);
    }

    100% {
        transform: translate(52vw, 50vh);
    }
}

@-o-keyframes fly-around-4 {
    0% {
        transform: translate(52vw, 50vh);
    }

    33% {
        transform: translate(13.665573745835104vw, 2.314085783752593vh);
    }

    66% {
        transform: translate(70.08688741361918vw, 5.804331585651835vh);
    }

    100% {
        transform: translate(52vw, 50vh);
    }
}

@keyframes fly-around-4 {
    0% {
        transform: translate(52vw, 50vh);
    }

    33% {
        transform: translate(13.665573745835104vw, 2.314085783752593vh);
    }

    66% {
        transform: translate(70.08688741361918vw, 5.804331585651835vh);
    }

    100% {
        transform: translate(52vw, 50vh);
    }
}

.fly-5 {
    animation: fly-around-5 25s infinite;
}

@-moz-keyframes fly-around-5 {
    0% {
        transform: translate(65vw, 50vh);
    }

    33% {
        transform: translate(84.4773196747352vw, 19.561358692444575vh);
    }

    66% {
        transform: translate(85.8521369506211vw, 29.856214300499325vh);
    }

    100% {
        transform: translate(65vw, 50vh);
    }
}

@-webkit-keyframes fly-around-5 {
    0% {
        transform: translate(65vw, 50vh);
    }

    33% {
        transform: translate(84.4773196747352vw, 19.561358692444575vh);
    }

    66% {
        transform: translate(85.8521369506211vw, 29.856214300499325vh);
    }

    100% {
        transform: translate(65vw, 50vh);
    }
}

@-o-keyframes fly-around-5 {
    0% {
        transform: translate(65vw, 50vh);
    }

    33% {
        transform: translate(84.4773196747352vw, 19.561358692444575vh);
    }

    66% {
        transform: translate(85.8521369506211vw, 29.856214300499325vh);
    }

    100% {
        transform: translate(65vw, 50vh);
    }
}

@keyframes fly-around-5 {
    0% {
        transform: translate(65vw, 50vh);
    }

    33% {
        transform: translate(84.4773196747352vw, 19.561358692444575vh);
    }

    66% {
        transform: translate(85.8521369506211vw, 29.856214300499325vh);
    }

    100% {
        transform: translate(65vw, 50vh);
    }
}

.fly-6 {
    animation: fly-around-6 26s infinite;
}

@-moz-keyframes fly-around-6 {
    0% {
        transform: translate(78vw, 50vh);
    }

    33% {
        transform: translate(5.657236005223897vw, 97.66651551839595vh);
    }

    66% {
        transform: translate(57.94049887646306vw, 77.17694408263193vh);
    }

    100% {
        transform: translate(78vw, 50vh);
    }
}

@-webkit-keyframes fly-around-6 {
    0% {
        transform: translate(78vw, 50vh);
    }

    33% {
        transform: translate(5.657236005223897vw, 97.66651551839595vh);
    }

    66% {
        transform: translate(57.94049887646306vw, 77.17694408263193vh);
    }

    100% {
        transform: translate(78vw, 50vh);
    }
}

@-o-keyframes fly-around-6 {
    0% {
        transform: translate(78vw, 50vh);
    }

    33% {
        transform: translate(5.657236005223897vw, 97.66651551839595vh);
    }

    66% {
        transform: translate(57.94049887646306vw, 77.17694408263193vh);
    }

    100% {
        transform: translate(78vw, 50vh);
    }
}

@keyframes fly-around-6 {
    0% {
        transform: translate(78vw, 50vh);
    }

    33% {
        transform: translate(5.657236005223897vw, 97.66651551839595vh);
    }

    66% {
        transform: translate(57.94049887646306vw, 77.17694408263193vh);
    }

    100% {
        transform: translate(78vw, 50vh);
    }
}



.mosquito {
    width: 60px;
    position: absolute;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    display: none;
}

.bg-gra {
    background: linear-gradient(18deg, rgba(153, 69, 255, 1) 20%, rgba(20, 241, 149, 1) 100%);

}

.hero_sec {

    .overlay {
        margin-top: -16px !important;
    }

    .overlay_inner {
        margin-left: -60px !important;
        z-index: 1 !important;
    }

    .overlay_inner_left {
        margin-left: -70px !important;
    }

    .arrow span {
        display: block;
        width: 20px;
        height: 20px;
        border-bottom: 5px solid rgba(153, 69, 255, 1);
        border-right: 5px solid rgba(20, 241, 149, 1);
        transform: rotate(45deg);
        margin: -4px 3px;
        animation: animate 2s infinite;
        z-index: -1 !important;
    }

    @keyframes animate {
        0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
        }
    }
}

.bg {
    position: relative;
    background: black;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    padding: 2rem;
    overflow: hidden;
}

.stage {
    height: 100%;
    padding: 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.logo {
    flex: 7;
    padding: 2rem;
}

.text {
    flex: 3;

    .cls-2 {
        fill: #fff;
        fill-rule: evenodd;
    }
}

.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_1_);
}

.st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_2_);
}

.st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#SVGID_3_);
}

@keyframes glow {
    0% {
        box-shadow: 0px 0px 15px 5px rgba(181, 103, 223, 0);
    }

    100% {
        box-shadow: 0px 0px 15px 5px rgba(17, 243, 177, .75);
    }
}

.animate {
    .stage {
        animation: fadeIn 1s;
    }

    &.bg {
        animation: glow 1.25s infinite alternate;
    }

    .st0 {
        animation: fadeInRight 1s;
    }

    .st1 {
        animation: fadeInLeft 1s;
    }

    .st2 {
        animation: fadeInRight 1s;
    }

    .text {
        animation: fadeInUp 1s;
    }
}

.timer-section {
    .timer {
        span {
            color: #fff;
            font-weight: 600;
            font-size: 22px;
            background-color: #000;
            padding: 0.5rem;
            margin: 0.5rem;
            border-radius: 4px;
        }
    }
}

@media screen and (max-width: 768px) {
    .hero_sec {
        .overlay_inner {
            margin-left: -10px !important;
            z-index: 1 !important;
        }

        .overlay_inner_left {
            margin-left: 0px !important;
        }
    }
}